<template>
  <div class="pageContol listWrap templateList formCom courseType1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">知识点分类</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="分类名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6em;">分类名称:</span>
              <el-input size="small" placeholder="请输入分类名称" v-model="retrievalData.knowledgeName" clearable>
              </el-input>
            </div>
            <el-button style="margin-left: 20px" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" round icon="el-icon-plus" @click="setClassifyData('', '', 1)">新增知识点分类</el-button>
            <el-button class="bgc-bv" round @click="knowledgeImport">知识点导入</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="zdcz" @click="setDefaultTree">一键折叠/展开</div>
            <el-table ref="table" v-if="setTableparameter.refreshTable" :data="setTableparameter.courseTypeTree"
              :header-cell-style="tableHeader" height="calc(100% - 30px)" size="small" style="width: 100%"
              row-key="knowledgeIdPath" @cell-mouse-enter="mouseenter" @cell-mouse-leave="mouseleave"
              :default-expand-all="setTableparameter.defaultTree" :cell-class-name="setListHighlight">
              <el-table-column prop="knowledgeName" label="分类名称" align="left"></el-table-column>
              <el-table-column prop="knowledgeWeightLevelStr" label="权重级别" align="left"></el-table-column>
              <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
              <el-table-column prop="address" label="操作" align="center" width="280px">
                <div slot-scope="scope" class="">
                  <el-button size="mini" type="text"
                    @click="setClassifyData(scope.row, scope.$index, 2)">新增子分类</el-button>
                  <el-button size="mini" type="text" @click="setClassifyData(scope.row, scope.$index, 3)">编辑</el-button>
                  <el-button size="mini" type="text" @click="setWeightData(scope.row, scope.$index)"
                    v-if="scope.row.parentKnowledgeId == '0' && scope.row.children.length">设置权重</el-button>
                  <el-button size="mini" type="text" :disabled="scope.row.children.length"
                    @click="deleteClassifyData(scope.row)">删除</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!--鉴定机构通用导入:businessType 导入业务类型标识;importType导入类型(知识点); unit  单位(条);fileType 文件类型-->
    <accreditationAgencyImport ref="accreditationAgencyImport" @eventBus="accreditationAgencyImportBack"
      businessType="EXAM_BANK_KNOWLEDGE_IMPORT" importType="知识点" unit="条" fileType="EXCEL" />
    <!-- 新增&编辑节点数据 - 弹框 -->
    <el-dialog :title="classifyData.dialogTitle" :visible.sync="classifyData.dialogVisible" width="30%" center>
      <el-form ref="courseTypeDialogFromRef" :model="classifyData" :rules="classifyDataRules" size="small"
        label-width="120px">
        <el-form-item label="知识点名称" prop="knowledgeName" size>
          <el-input v-model="classifyData.knowledgeName" maxlength="30" show-word-limit
            :disabled="classifyData.firstNameDisabled"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="classifyData.dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitClassifyData()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 设置权重 - 弹框 -->
    <el-dialog title="设置权重" :visible.sync="weightData.dialogVisible" top="20px" width="70%" center>
      <el-form ref="weightDataRef" :model="weightData" :rules="weightDataRules" size="small" label-width="120px">
        <el-form-item label="设置权重：" prop="lvId">
          <el-radio-group v-model="weightData.lvId" @input="getKnowledgePointsList">
            <el-radio :label="item.lvId" v-for="(item, index) in weightData.lvTabList" :key="index">{{ item.lvName
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="权重值总计：">
          {{ weightData.knowledgePointsTotal }}
        </el-form-item>
      </el-form>
      <el-table ref="multipleTable" :data="weightData.knowledgePointsList" :height="tableHeight" size="small"
        tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader" stripe>
        <el-table-column label="序号" align="center" type="index" width="100px" :index="indexMethod" />
        <el-table-column label="知识点名称" align="left" prop="knowledgeName" min-width="150" show-overflow-tooltip />
        <el-table-column label="级别" align="left" prop="knowledgeWeightLevelStr" min-width="100" show-overflow-tooltip />
        <el-table-column label="父级" align="left" prop="parentKnowledgeName" min-width="200" show-overflow-tooltip />
        <el-table-column label="权重" align="left" prop="knowledgeWeightValue" min-width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-input v-model="scope.row.knowledgeWeightValue"
              @keyup.native="scope.row.knowledgeWeightValue = zF.oninput2(scope.row.knowledgeWeightValue, 1)"
              @blur="blurWeight(scope.row)" size="small"></el-input>
          </template>
        </el-table-column>
        <Empty slot="empty" />
      </el-table>
      <p style="color:red;margin-top: 20px;">提醒:知识点一旦设定，切换不同的级别时，已有的规则作废，并且已经按规则生成的试卷不受影响</p>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="weightData.dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitweightData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import List from "@/mixins/List";
import Empty from "@/components/Empty.vue";
// 鉴定机构通用导入
import accreditationAgencyImport from "@/components/accreditationAgencyImport.vue";

export default {
  name: "inkQuestionBank/KnowledgePoints/KnowledgePointsList",
  mixins: [List],
  components: {
    accreditationAgencyImport,
    Empty
  },
  data() {
    return {
      // 检索数据
      retrievalData: {
        knowledgeName: "", //分类名称
      },
      // 列表相关参数配置
      setTableparameter: {
        // 列表渲染
        refreshTable: false,
        // 列表展开树节点
        defaultTree: true,
        // 列表当前高亮的 knowledgeId
        highlightKnowledgeId: "",
        // 列表数据
        courseTypeTree: [],
      },
      // 新增&编辑&新增子节点数据
      classifyData: {
        dialogVisible: false, //弹框状态
        indexs: -1, // 当前元素的下标
        dialogTitle: "", //弹框标题
        parentId: "", //父节点id 
        knowledgeId: "", //当前节点id
        knowledgeName: "", //知识点名称
      },
      // 新增&编辑&新增子节点数据 - 数据校验
      classifyDataRules: {
        knowledgeName: [
          {
            required: true,
            message: "请输入知识点名称",
            trigger: "blur",
          },
        ],
      },
      // 设置权重数据
      weightData: {
        dialogVisible: false, //弹框状态
        indexs: -1, // 当前元素的下标
        lvId: "", // 设置权重
        lvTabList: [], // 设置权重单选数据
        knowledgePointsTotal: 0, // 权重值总计
        knowledgePointsList: [], // 知识点列表数据
      },
      // 设置权重数据 - 数据校验
      weightDataRules: {
        lvId: [
          {
            required: true,
            message: "请选择设置权重",
            trigger: "change",
          },
        ],
      }
    };
  },
  created() {
  },
  computed: {},
  mounted() { },
  methods: {
    // 导入知识点
    knowledgeImport() {
      this.$refs.accreditationAgencyImport.showPopup(0)
    },
    // 导入知识点 - 组件的回调
    accreditationAgencyImportBack() {
      this.getData(-1)
    },
    // 获取列表数据
    async getData() {
      this.setTableparameter.refreshTable = false;
      let params = {};
      if (this.retrievalData.knowledgeName) {
        params.knowledgeName = this.retrievalData.knowledgeName
      }
      return this.$post("/biz/exam/bank/knowledge/tree ", params, 3000, true, 6)
        .then((res) => {
          console.log(res)
          if (res.status == "0") {
            this.setTableparameter.courseTypeTree = res.data || [];
            this.$nextTick(() => {
              this.setTableparameter.refreshTable = true;
            });
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 一键折叠/展开
    setDefaultTree() {
      this.setTableparameter.highlightKnowledgeId = "";
      this.setTableparameter.defaultTree = !this.setTableparameter.defaultTree;
      this.setTableparameter.refreshTable = false;
      setTimeout(() => {
        this.setTableparameter.refreshTable = false;
      }, 100)
      setTimeout(() => {
        this.setTableparameter.refreshTable = true;
      }, 200)
    },
    // 新增&编辑 - 子节点数据 - 打开弹框
    setClassifyData(row, indexs, type) {
      this.classifyData = this.$options.data().classifyData;
      this.classifyData.dialogVisible = true;
      this.classifyData.indexs = indexs;
      if (type == 1 || type == 2) {
        this.classifyData.dialogTitle = '新增知识点';
        this.classifyData.parentId = row.knowledgeId || 0;
      }
      if (type == 3) {
        this.classifyData.dialogTitle = '编辑知识点';
        this.classifyData.knowledgeId = row.knowledgeId;
        this.classifyData.knowledgeName = row.knowledgeName;
      }
    },
    // 新增&编辑 - 子节点数据 - 弹框确定
    submitClassifyData() {
      this.$refs["courseTypeDialogFromRef"].validate((valid) => {
        if (valid) {
          let url = '';
          let params = {
            knowledgeName: this.classifyData.knowledgeName,
          };
          if (this.classifyData.parentId || typeof this.classifyData.parentId == 'number') {// 新增节点&新增子节点
            params.parentId = this.classifyData.parentId;
            url = '/biz/exam/bank/knowledge/insert'
          } else {// 编辑节点
            params.knowledgeId = this.classifyData.knowledgeId;
            url = '/biz/exam/bank/knowledge/modify'
          }
          this.$post(url, params, 3000, true, 6)
            .then((res) => {
              if (res.status == 0) {
                this.getData().then(() => {
                  // 展开树节点
                  if (this.setTableparameter.defaultTree) {
                    this.$nextTick(() => {
                      this.zF.tableScrollToRow(this.$refs.table, this.classifyData.indexs); // 滚动到指定为止
                      this.setTableparameter.highlightKnowledgeId = params.parentId || params.knowledgeId; // 行高亮
                    })
                  }
                  this.classifyData.dialogVisible = false;
                  this.$message({
                    type: "success",
                    message: "设置成功",
                  });
                });
              }
            })
            .catch(() => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 新增&编辑 - 列表高亮提示当前操作的行
    setListHighlight(row) {
      if (row.row.knowledgeId == this.setTableparameter.highlightKnowledgeId) {
        return 'gl'
      }
    },
    // 设置权重数据 - 弹窗打开
    setWeightData(row, indexs) {
      this.weightData = this.$options.data().weightData;
      this.weightData.dialogVisible = true;
      this.weightData.knowledgeId = row.knowledgeId;
      this.weightData.indexs = indexs;
      this.getRadioData();
    },
    // 设置权重数据 - 获取：设置权重单选数据
    getRadioData() {
      this.$post('/biz/exam/bank/knowledge/weight/tab/list', {
        knowledgeId: this.weightData.knowledgeId
      }, 3000, true, 6)
        .then(res => {
          console.log(res)
          this.weightData.lvId = res.data?.lvId ?? "";
          this.weightData.lvTabList = res.data?.lvTabList ?? [];
          if (this.weightData.lvId) {
            this.getKnowledgePointsList(this.weightData.lvId);
          }
        })
        .catch(() => {
          return;
        });
    },
    // 设置权重数据 - 点击：设置权重 获取列表数据
    getKnowledgePointsList(val) {
      this.weightData.knowledgePointsTotal = 0;
      this.$post('/biz/exam/bank/knowledge/weight/level/list', {
        knowledgeId: this.weightData.knowledgeId,
        lvId: val
      }, 3000, true, 6)
        .then(res => {
          for (let i = 0; i < res.data.length; i++) {
            if (!res.data[i].knowledgeWeightValue) {
              res.data[i].knowledgeWeightValue = "";
            }
            this.weightData.knowledgePointsTotal += Number(res.data[i].knowledgeWeightValue);
          }
          this.weightData.knowledgePointsTotal = this.weightData.knowledgePointsTotal.toFixed(1); // 为了去浮点
          this.weightData.knowledgePointsList = res?.data ?? [];
        })
        .catch(() => {
          return;
        });
    },
    // 设置权重数据 - 改变权重，计算权重总值
    blurWeight(row) {
      this.weightData.knowledgePointsTotal = 0;
      for (let i = 0; i < this.weightData.knowledgePointsList.length; i++) {
        this.weightData.knowledgePointsTotal += Number(this.weightData.knowledgePointsList[i].knowledgeWeightValue);
      }
      this.weightData.knowledgePointsTotal = this.weightData.knowledgePointsTotal.toFixed(1); // 为了去浮点
    },
    // 设置权重数据 - 弹窗确定
    submitweightData() {
      this.$refs["weightDataRef"].validate((valid) => {
        if (valid) {
          // 权重值总计 > 100 给出提示
          if (Number(this.weightData.knowledgePointsTotal) < 100) {
            this.$confirm("权重未设置完成，不足100, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              closeOnClickModal: false,
              type: "warning",
            })
              .then(() => {
                this.tjData();
              })
              .catch(() => { });
          } else {
            this.tjData();
          }
        } else {
          this.$message({
            type: "warning",
            message: "请先设置权重",
          });
        }
      })
    },
    // 设置权重数据 - 弹窗确定 - 提交接口 
    tjData() {
      let arr = [];
      for (let i = 0; i < this.weightData.knowledgePointsList.length; i++) {
        arr.push({
          knowledgeId: this.weightData.knowledgePointsList[i].knowledgeId,
          knowledgeWeightValue: this.weightData.knowledgePointsList[i].knowledgeWeightValue
        })
      }
      this.$post('/biz/exam/bank/knowledge/weight/save', {
        knowledgeId: this.weightData.knowledgeId,
        lvId: this.weightData.lvId,
        knowledgeList: arr,
        knowledgePointsTotal: this.weightData.knowledgePointsTotal,
      }, 3000, true, 6)
        .then(res => {
          this.getData().then(res => {
            // 展开树节点
            if (this.setTableparameter.defaultTree) {
              this.$nextTick(() => {
                this.zF.tableScrollToRow(this.$refs.table, this.weightData.indexs); // 滚动到指定为止
                this.setTableparameter.highlightKnowledgeId = this.weightData.knowledgeId; // 行高亮
              })
            }
            this.weightData.dialogVisible = false;
            this.$message({
              type: "success",
              message: "设置成功",
            });
          });
        })
        .catch(() => {
          return;
        });
    },
    // 删除
    deleteClassifyData(row) {
      this.$confirm("删除后，将不可恢复", "确定删除吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post(
            "/biz/exam/bank/knowledge/remove",
            { knowledgeId: row.knowledgeId },
            3000,
            true,
            6
          ).then((res) => {
            res.status === "0"
              ? this.getData()
              : this.$message.error(res.message || "刪除失败！");
          });
        })
        .catch(() => {
          return;
        });
    },
    //排序单元格 鼠标移入事件
    mouseenter(row, column, cell, event) {
      // console.log(row)
    },
    //排序单元格 鼠标移除事件
    mouseleave(row, column, cell, event) {
      // console.log(row)
    },
  },
};
</script>
<style lang="less" scoped>
.summary-title {
  display: flex;
  padding-left: 12px;

  .summary-title-item {
    margin-right: 20px;
  }
}

.zdcz {
  color: #409EFF;
  font-size: 12px;
  margin-bottom: 10px;
  cursor: pointer;
}

/deep/ .gl {
  background: #ffc107;
}
</style>