<!--鉴定机构通用导入-->

<template>
    <!--导入弹窗-->
    <el-dialog :title="importType + '导入'" :visible.sync="centerDialogVisible" v-if="centerDialogVisible" width="50%"
        center :before-close="doPopupClose" class="exportdialog">
        <div class="flexdcc">
            <div class="export-box" v-if="fileType == 'EXCEL'">
                <h1>
                    1
                    <span>下载并填写导入模板</span>
                </h1>
                <div class="df">
                    <span style="
                        margin-top: 20px;
                        margin-right: 20px;
                        color: #409eff;
                        cursor: pointer;
                      " @click="downloadExcelgs(templateInfo.templatePath)">
                        {{ templateInfo.templateName }}
                    </span>
                </div>
            </div>
            <div class="export-box">
                <h1>
                    {{ fileType == 'EXCEL' ? '2' : '1' }}
                    <span>{{ fileType == 'EXCEL' ? '导入编写好的Excel文档' : fileType == 'ZIP' ? '导入压缩好的ZIP压缩包' : '导入文件' }}</span>
                </h1>
                <div class="flexdc">
                    <!-- 插槽 -->
                    <slot name="header"></slot>
                    <p v-if="desc" style="white-space: pre-wrap;margin-top: 20px;line-height: 1.5;">{{ desc }}</p>
                    <div class="df" style="margin: 20px 0 10px">
                        <el-upload class="upload-demo upload-workers" :action="actionUrl" :on-error="handleError"
                            :on-success="handleSuccess" :on-change="uploadChange" :show-file-list="false"
                            :auto-upload="false">
                            <el-button class="bgc-bv"
                                style="font-size: 12px!important;; width: 80%!important;">浏览</el-button>
                        </el-upload>
                        <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
                            当前选择文件：
                            <span style="color: #f46173">{{ fileName }}</span>
                        </p>
                        <p v-else style="margin-top: 10px; margin-left: 10px">
                            未选择文件
                        </p>

                    </div>
                    <div v-if="complete > 0">
                        <el-progress :text-inside="true" :stroke-width="20" :percentage="complete"></el-progress>
                    </div>
                    <div>
                        <el-button class="bgc-bv" size="mini" style="margin-top: 10px; height: 35px"
                            :disabled="doExportInDisabled" @click="doExport">开始导入</el-button>
                    </div>
                    <div style="margin-top: 10px" v-if="progressVal > 0">
                        <el-progress :text-inside="true" :stroke-width="20" :percentage="progressVal"></el-progress>
                    </div>
                </div>
            </div>
            <div class="export-box">
                <h1>
                    {{ fileType == 'EXCEL' ? '3' : '2' }}
                    <span>导入结果</span>
                </h1>
                <div class="df studentNum">
                    <span>{{ importType }}总数量:{{ totalNum }}{{ unit }};</span>
                    <span>成功:{{ correctNum }}{{ unit }};</span>
                    <span>失败:{{ errorNum }}{{ unit }};</span>
                </div>
                <div>
                    <el-button class="bgc-bv" style="margin-top: 15px; height: 35px" size="mini"
                        :disabled="errorNum == '0'" @click="doExportError">导出错误数据</el-button>
                </div>
                <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">
                    有导入失败{{ importType }}，请导出错误数据，将导入失败{{ importType }}调整后重新上传
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "accreditationAgencyImport",
    // businessType // 导入业务类型标识 [ BUSINESS_TYPE ]
    // importType // 导入类型 学员
    // unit // 单位 学员-人
    // fileType // 文件类型  EXCEL  ZIP
    props: {
        businessType: {
            type: String,
            default: "CT_APPLY_USER_IMPORT"

        },
        importType: {
            type: String,
            default: "学员"

        },
        unit: {
            type: String,
            default: "人"

        },
        fileType: {
            type: String,
            default: "EXCEL"

        },
        desc: {
            type: String,
            default: ""
        },
        filePath: {//本地模板文件
            type: String,
            default: ""
        },
        //  考务学员导入业务 EXAMINATION_AFFAIRS_USER_IMPORT;考试时间的list
        activityExamBOS: {
            type: Array,
            default: () => []
        }
    },
    components: {
    },
    data() {
        return {
            // 导入
            centerDialogVisible: false,
            totalNumlb: 0,
            correctNumlb: 0,
            errorNumlb: 0,
            progressVal: 0,
            batchId: "",
            templateInfo: "", // 模板数据
            fileName: "",
            fileKey: "",
            excelUrl: "",
            doExportInDisabled: false, //开始导入按钮禁用
            trainTaskInfo: {},
            complete: 0
        };
    },
    // watch: {
    //   editPopupData: {
    //     //深度监听，可监听到对象、数组的变化
    //     handler(val, oldVal) {
    //       // 200-安全培训教育制度；

    //     },
    //     immediate: true,
    //     deep: true, //true 深度监听
    //   },
    // },
    computed: {
        ...mapGetters({
            downloadItems: "getDownloadItems",
        }),
    },
    methods: {
        /* 导入 */
        showPopup(businessId) {
            this.businessId = businessId
            // console.log('businessId',businessId)
            this.totalNum = "0";
            this.errorNum = "0";
            this.correctNum = "0";
            this.batchId = "";
            if (this.fileType == 'EXCEL') {
                if (this.filePath) {
                    this.templateInfo = {
                        templateName: this.filePath.split("/").pop(),
                        templatePath: this.filePath,
                    }
                } else {
                    this.getTemplate();
                }
            }
            this.centerDialogVisible = true;
        },
        /* 下载模板 */
        downloadExcelgs(templatePath) {
            /**
             * 下载模板
             * @param param 参数
             */
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = templatePath;
            let templateNames = templatePath.slice(8);
            console.log(templateNames);
            link.setAttribute("download", templateNames);
            document.body.appendChild(link);
            link.click();
        },
        // 上传文件
        uploadChange(file) {
            this.fileName = file.name;
            let size = file.size / 1024 / 1024;
            // let fileName = file.name.split(".");
            let extension = file.name.substring(file.name.lastIndexOf(".") + 1);

            if (this.fileType == 'EXCEL') {
                const isXLSX = extension === "XLSX";
                const isxlsx = extension === "xlsx";
                const isXLS = extension === "XLS";
                const isxls = extension === "xls";
                if (!isXLSX && !isxlsx && !isXLS && !isxls) {
                    this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
                    this.fileName = ''
                    return;
                }
                if (size > 100) {
                    this.$message.error("文件大小不能超过100M");
                    this.fileName = ''
                    return;
                }
            }
            if (this.fileType == 'ZIP') {
                const isZIP = extension === "ZIP";
                const iszip = extension === "zip";
                if (!isZIP && !iszip) {
                    this.$message.error("只能上传后缀是.ZIP或者.zip的文件");
                    this.fileName = ''
                    return;
                }
                if (size > 100) {
                    this.$message.error("文件大小不能超过100M");
                    this.fileName = ''
                    return;
                }
            }

            let formData = new FormData();
            formData.append("folder ", "TEMP");
            formData.append("file ", file.raw);
            formData.append("fileType ", extension);
            console.log(formData)
            this.$Postformat("/sys/file/async/upload", formData, false, 0, 6, {
                onUploadProgress: progressEvent => {
                    let complete = ~~((progressEvent.loaded / progressEvent.total) * 100 / 2);
                    console.log(complete);
                    this.complete = complete
                }
            })
                .then((result) => {
                    this.getUploadProcess(result.data)
                })
                .catch((err) => {
                    return;
                });
        },
        // 上传文件 - 获取进度
        getUploadProcess(batchId) {
            console.log(batchId);
            this.$post('/sys/file/async/upload/progress', { batchId }, true, 3000, 6)
                .then((res) => {
                    console.log(res)
                    const { progress, fileKey, status } = res.data
                    if (res.status == '0') {
                        if (progress == 100 && fileKey && status) {
                            console.log('成功');
                            this.complete = progress
                            this.fileKey = fileKey
                        } else {
                            this.complete = progress
                            setTimeout(() => {
                                this.getUploadProcess(batchId);
                            }, 2000);
                        }
                    } else {
                        if (res.message) {
                            this.$message({
                                type: "error",
                                message: res.message,
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // 开始导入
        doExport() {
            // 考务学员导入业务 EXAMINATION_AFFAIRS_USER_IMPORT
            if(this.businessType == 'EXAMINATION_AFFAIRS_USER_IMPORT' && this.activityExamBOS.length<1){
                this.$message({
                    message: "请选择考试时间",
                    type: "warning",
                });
                return;
            }
            if (this.fileKey == "") {
                this.$message({
                    message: this.complete > 0 ? "请等待文件上传完成" : "请选择文件",
                    type: "warning",
                });
            } else {
                this.doExportInDisabled = true;
                if (this.fileKey) {
                    const parmar = {
                        businessId: this.businessId,
                        businessType: this.businessType,
                        fileKey: this.fileKey,
                    };
                    // 考务学员导入业务 EXAMINATION_AFFAIRS_USER_IMPORT
                    if(this.businessType == 'EXAMINATION_AFFAIRS_USER_IMPORT'){
                        parmar.activityExamBOS = this.activityExamBOS;
                    }
                    console.log(parmar)
                    this.$post("/sys/import/run", parmar, 5000, true, 6)
                        .then((res) => {
                            if (res.status == 0) {
                                if (res.status == 0) {
                                    console.log(res.data);
                                    this.doProgress(res.data, this.projectId);
                                }
                            }
                        })
                        .catch(() => {
                            return;
                        });
                } else {
                    this.$message({
                        message: "请选择文件",
                        type: "warning",
                    });
                }
            }
        },
        // 开始导入 - 获取导入进度
        doProgress(batchId, projectId) {
            this.$post(
                "/sys/import/progress",
                {
                    batchId,
                    businessId: this.businessId,
                    businessType: this.businessType,
                },
                5000, true, 6
            ).then((ret) => {
                if (!ret.data.status) {
                    this.progressVal = ret.data.progress;
                    setTimeout(() => {
                        this.doProgress(batchId, projectId);
                    }, 2000);
                } else {
                    if (ret.data.message) {
                        this.$message({
                            type: "error",
                            message: ret.data.message,
                        });
                    } else {
                        this.totalNum = ret.data.totalNum;
                        this.errorNum = ret.data.errorNum;
                        this.correctNum = ret.data.correctNum;
                        this.batchId = ret.data.batchId;
                        this.progressVal = ret.data.progress;
                        this.$message({
                            type: "success",
                            message: "导入成功",
                        });
                    }
                }
            });
        },
        // 导出错误数据
        doExportError() {
            // console.log(this.batchId);
            if (this.batchId == "" || this.fileKey == "") {
                this.$message({
                    message: "还没有导入数据，请先导入文档",
                    type: "warning",
                });
            } else {
                this.$post(
                    "/sys/import/error/export",
                    {
                        batchId: this.batchId,
                        businessId: this.businessId,
                        businessType: this.businessType,
                    },
                    5000, true, 6
                ).then((res) => {
                    if (res.status == 0) {
                        window.open(res.data);
                    }
                });
            }
        },
        // 导入 - 关闭弹框
        doPopupClose() {
            this.centerDialogVisible = false;
            this.newvalues = [];
            this.fileKey = "";
            this.fileName = "";
            this.progressVal = 0;
            this.doExportInDisabled = false;
            this.ignorevalidity = false
            this.complete = 0
            this.$emit("eventBus");
            // this.getData();
        },
        /* 获取模板 */
        getTemplate() {
            let params = {
                businessId: this.businessId,
                businessType: this.businessType
            }
            this.$post('/sys/import/template', params, 3000, false, 6).then((ret) => {
                this.templateInfo = ret.data || [];
            });
        },
    },
    mounted() { },
    created() { },
};
</script>

<style lang="less" scoped>
/*导入*/
.exportdialog {
    .el-dialog__title {
        font-size: 16px;
    }

    .export-box {
        width: 100%;
        margin-bottom: 20px;

        h1 {
            padding: 0 5px 10px;
            display: flex;
            align-items: flex-end;
            font-size: 40px;
            border-bottom: 1px dashed #ccc;

            span {
                margin-left: 20px;
                font-size: 14px;
            }
        }

        >div {
            padding-left: 30px;
        }

        .el-button.is-disabled,
        .el-button.is-disabled:focus,
        .el-button.is-disabled:hover {
            color: #fff;
            background: #c3c3c3;
            border: none;
        }
    }
}

.docsDialog {
    .el-form-item {
        margin-bottom: 0.5rem;
    }
}

.studentNum {
    margin: 20px 0 10px;

    span {
        margin: 0 10px;
    }
}

.exportdialog {
    .upload-workers {
        width: 80px;
        height: 35px;
        line-height: 35px;

        /deep/.el-upload {
            height: 35px !important;
            width: 80px;
            min-width: 80px;
            border: none !important;

            .el-button {
                padding: 5px 0;
                width: 100% !important;
            }
        }
    }
}
</style>
